<template>
  <v-expansion-panels v-model="openPanel">
    <v-expansion-panel>
      <v-expansion-panel-header>{{ $t('personal') }}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form>
          <v-text-field
            :disabled="isUpdating"
            filled
            dense
            :label="$t('name')"
            required
            v-model="person.address.name_line"
          />
          <v-text-field
            :disabled="isUpdating"
            filled
            dense
            :label="$t('ssn')"
            required
            v-model="person.ssn"
          />
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>{{ $t('contact') }}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form>
          <v-text-field
            :disabled="isUpdating"
            filled
            dense
            :label="$t('phone')"
            v-model="person.phone"
          />
          <v-text-field
            :disabled="!person || !person.hasOwnProperty('email')"
            filled
            dense
            :label="$t('email')"
            v-model="person.email"
            type="email"
          />
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>{{ $t('permissions') }}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form>
          <v-text-field
            :disabled="isUpdating"
            dense
            filled
            :label="$t('pin')"
            v-model="person.pin"
          />
          <v-autocomplete
            :disabled="isUpdating"
            dense
            filled
            multiple
            :label="$t('allowedDiscounts')"
            :items="discountRange"
            v-model="person.allowed_discounts"
            :no-data-text="$t('couldNotFindDiscount')"
            persistent-hint
            :hint="$t('discountsThisStaffIsAllowedToGive')"
          />
          <v-switch
            :disabled="isUpdating"
            dense
            :label="$t('posAdmin')"
            :hint="$t('thisPermissionAllowsStaffUnrestrictedAccessToSettingsInTheApp')"
            persistent-hint
            v-model="person.is_pos_admin"
          />
          <v-switch
            :disabled="isUpdating"
            dense
            :label="$t('hideInEpos')"
            :hint="$t('hidesThisStaffMemberInTheSalesCloudEPOSApp')"
            persistent-hint
            v-model="person.hideInEpos"
          />
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>{{ $t('rates') }}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <StaffRates
          :is-updating="isUpdating"
          :person="person"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import StaffRates from "@/components/person/StaffRates"

/**
 * @property {Number} public_holidays
 * @property {Number} major_public_holidays
 */
export default {
	name: "StaffInformation",
	components: {
		StaffRates
	},
	props: {
		isUpdating: {
      type: Boolean
    },
		person: {
      type: Object,
      default: () => {}
    }
	},
	data() {
		return {
			openPanel: 0,
		}
	},
	watch: {
		//
	},
	methods: {
		//
	},
	beforeMount() {
		if(this.person && this.person.allowed_discounts && this.person.allowed_discounts.length) {
			this.person.allowed_discounts = this.person.allowed_discounts.sort((a,b) => a - b)
		}
	},
	mounted() {
		//
	},
	computed: {
		notReady() {
			return this.$store.state.notReady
		},
		discountRange() {
			return [...Array(20).keys()].map(x => {
				return (x+1)*5
			})

			// const array = []
			//
			// for(let i = 0; i <= 100; i++) {
			// 	array.push(i)
			// }
			//
			// return array
		}
	}
}
</script>

<style scoped>

</style>
