<template>
  <div>
    <v-row align="center">
      <v-col>
        <v-icon
          small
          left
        >
          mdi-information
        </v-icon>
        <span
          class="text--secondary"
          style="vertical-align: middle"
        > {{ $t('belowAreHourlyRates') }}</span>
      </v-col>
    </v-row>
    <v-form>
      <v-text-field
        :disabled="isUpdating"
        type="number"
        dense
        filled
        :label="$t('standard')"
        v-model="standardRate.amount"
        :suffix="standardRate.currency_code"
      />
      <v-text-field
        :disabled="isUpdating"
        type="number"
        dense
        filled
        :label="$t('evening')"
        v-model="eveningRate.amount"
        :suffix="eveningRate.currency_code"
      />
      <v-text-field
        :disabled="isUpdating"
        type="number"
        dense
        filled
        :label="$t('weekend')"
        v-model="weekendRate.amount"
        :suffix="weekendRate.currency_code"
      />
      <v-text-field
        :disabled="isUpdating"
        type="number"
        dense
        filled
        :label="$t('publicHolidays')"
        v-model="publicHolidayRate.amount"
        :suffix="publicHolidayRate.currency_code"
      />
      <v-text-field
        :disabled="isUpdating"
        type="number"
        dense
        filled
        :label="$t('majorPublicHolidays')"
        v-model="majorPublicHolidayRate.amount"
        :suffix="majorPublicHolidayRate.currency_code"
      />
      <v-autocomplete
        :disabled="isUpdating"
        filled
        :label="$t('currency')"
        v-model="currencyType"
        :items="currencies"
        item-text="code"
        item-value="code"
        :no-data-text="$t('currencyDoesNotExist')"
        :filter="currencyFilter"
        persistent-hint
        :hint="$t('thisWillSetTheCurrencyOfAllOfTheAboveRates')"
      >
        <template v-slot:item="{ item }">
          <span>{{ item.code }} <span class="font-italic text--secondary caption">{{ item.name }}</span></span>
        </template>
      </v-autocomplete>
    </v-form>
  </div>
</template>

<script>
export default {
	name: "StaffRates",
	props: {
		isUpdating: {
			type: Boolean
		},
		person: {
			type: Object,
			default: () => {
			}
		}
	},
	data() {
		return {
			currencyType: null,
			standardRate: {
				amount: 0,
				currency_code: null
			},
			eveningRate: {
				amount: 0,
				currency_code: null
			},
			weekendRate: {
				amount: 0,
				currency_code: null
			},
			publicHolidayRate: {
				amount: 0,
				currency_code: null
			},
			majorPublicHolidayRate: {
				amount: 0,
				currency_code: null
			}
		}
	},
	beforeMount() {
		if (this.person) {
			let c = []

			if (this.person.standardRate) {
				c.push(this.person.standardRate.currency_code)
				this.standardRate = this.person.standardRate
				if (this.standardRate.amount === null || this.standardRate.amount === undefined) {
					this.standardRate.amount = 0
				}
			}
			if (this.person.eveningRate) {
				c.push(this.person.eveningRate.currency_code)
				this.eveningRate = this.person.eveningRate
				if (this.eveningRate.amount === null || this.eveningRate.amount === undefined) {
					this.eveningRate.amount = 0
				}
			}
			if (this.person.weekendRate) {
				c.push(this.person.weekendRate.currency_code)
				this.weekendRate = this.person.weekendRate
				if (this.weekendRate.amount === null || this.weekendRate.amount === undefined) {
					this.weekendRate.amount = 0
				}
			}
			if (this.person.publicHolidayRate) {
				c.push(this.person.publicHolidayRate.currency_code)
				this.publicHolidayRate = this.person.publicHolidayRate
				if (this.publicHolidayRate.amount === null || this.publicHolidayRate.amount === undefined) {
					this.publicHolidayRate.amount = 0
				}
			}
			if (this.person.majorPublicHolidayRate) {
				c.push(this.person.majorPublicHolidayRate.currency_code)
				this.majorPublicHolidayRate = this.person.majorPublicHolidayRate
				if (this.majorPublicHolidayRate.amount === null || this.majorPublicHolidayRate.amount === undefined) {
					this.majorPublicHolidayRate.amount = 0
				}
			}
			c = c.reduce((acc, item) => {
				if (item && !acc.includes(item)) {
					acc.push(item)
				}
				return acc
			}, [])
			if (c.length === 1) {
				this.currencyType = c[0]
			}
		}
	},
	mounted() {
		if (this.defaultCurrency && !this.currencyType) {
			this.currencyType = this.defaultCurrency.code
		}
	},
	watch: {
		standardRate: {
			handler(value) {
				if (value) {
					this.person.standardRate = this.standardRate
					this.person.standardRate.amount *= 1
				}
			},
			deep: true
		},
		eveningRate: {
			handler(value) {
				if (value) {
					this.person.eveningRate = this.eveningRate
					this.person.eveningRate.amount *= 1
				}
			},
			deep: true
		},
		weekendRate: {
			handler(value) {
				if (value) {
					this.person.weekendRate = this.weekendRate
					this.person.weekendRate.amount *= 1
				}
			},
			deep: true
		},
		publicHolidayRate: {
			handler(value) {
				if (value) {
					this.person.publicHolidayRate = this.publicHolidayRate
					this.person.publicHolidayRate.amount *= 1
				}
			},
			deep: true
		},
		majorPublicHolidayRate: {
			handler(value) {
				if (value) {
					this.person.majorPublicHolidayRate = this.majorPublicHolidayRate
					this.person.majorPublicHolidayRate.amount *= 1
				}
			},
			deep: true
		},
		currencyType(value) {
			if (value) {
				this.standardRate.currency_code = value
				this.eveningRate.currency_code = value
				this.weekendRate.currency_code = value
				this.publicHolidayRate.currency_code = value
				this.majorPublicHolidayRate.currency_code = value
			}
		}
	},
	computed: {
		currencies() {
			return this.$store.state.currencies
		},
		defaultCurrency() {
			return this.$store.state.defaultCurrency
		},
	},
	methods: {
		currencyFilter(item, queryText, itemText) {
			if (itemText.toLowerCase().includes(queryText.toLowerCase())) {
				return itemText
			}
			if (item.name.toLowerCase().includes(queryText.toLowerCase())) {
				return itemText
			}
		}
	}
}
</script>

<style scoped>

</style>
